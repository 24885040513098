<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <BIconPencilSquare/>
            <strong> 드라이브 예약</strong>
            <span class="ml-2 font-weight-bolder bg-info" v-if="isUpdateMode">
              [
              <del v-if="dr.isCancled">{{dr.drvKey}}</del>
              <span v-if="!dr.isCancled">{{dr.drvKey}}</span>
              ]
            </span>
            &nbsp;
            <CBadge v-if="dr.isCnclReq && !dr.isCancled" color="warning" class="blink">예약 취소요청 상태</CBadge>
            <CBadge v-if="dr.isCancled" color="danger">취소된 예약입니다</CBadge>
            <div class="card-header-actions">
              <a
                href="https://drive.chungbang.co.kr/help"
                class="card-header-action"
                rel="noreferrer noopener"
                target="_blank"
              >
                <small class="text-muted">도움말</small>
              </a>
            </div>
          </CCardHeader>
          <CCardBody>

            <CRow class="mt-2">
              <CCol>
                <CRow>
                  <!-- 딜리버리/픽업 선택 -->
                  <CCol md="6" class="text-center">
                    <BFormGroup v-slot="{ ariaDescribedby }">
                        <BFormRadioGroup
                          v-model="dr.drvCode"
                          :options="drvCode.opt"
                          button-variant="outline-info"
                          :aria-describedby="ariaDescribedby"
                          buttons
                          style="min-width:80%"
                        >
                      </BFormRadioGroup>
                    </BFormGroup>
                  </CCol>

                  <!-- 편도/왕복 선택 -->
                  <CCol md="6" class="text-center">
                    <CRow>
                      <CCol>
                        <BFormGroup v-slot="{ ariaDescribedby }">
                          <BFormRadioGroup
                            v-model="dr.isRoundTrip"
                            :options="con.roundTrip.opt"
                            button-variant="outline-warning"
                            :aria-describedby="ariaDescribedby"
                            buttons
                            style="min-width:60%"
                          >
                          </BFormRadioGroup>
                        </BFormGroup>
                      </CCol>
                      <CCol>
                        <BInputGroup prepend="결제방식" class="border-primary">
                          <BSelect v-model="dr.payCode"
                                   id="payCode"
                                   :options="payCodeOpts"

                          />
                        </BInputGroup>
                      </CCol>
                    </CRow>

                  </CCol>
                </CRow>

                <CRow>

                  <CCol md="3">
                    <CInput
                      class="mt-2"
                      label="예약날짜"
                      type="date"
                      v-model="dr.drvYmd"
                      @input="isNow=false"
                    />
                  </CCol>
                  <CCol md="3">
                    <BFormGroup class="mt-2" label="예약시간" label-for="drvHms">
                      <BFormTimepicker
                        id="drvHms"
                        v-model="dr.drvHms"
                        locale="de"
                        :hour12="false"
                        @input="isNow=false"
                      />
                    </BFormGroup>
                  </CCol>
                  <CCol md="6">
                    <CRow>
                      <CCol>
                        <BFormCheckbox class="mt-5" v-model="isNow" @input="setDrvDate">즉시 예약</BFormCheckbox>
                      </CCol>
                      <CCol>

                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>



                <CRow class="mt-2">
                  <CCol md="6">
                    <CInput
                      placeholder="보내는분 이름을 입력해 주세요"
                      type="text"
                      v-model="dr.reqName"
                    >
                      <template #label>
                        <CRow class="mb-1">
                          <CCol>
                            출발지 성함
                          </CCol>
                          <CCol class="text-right">
                            <CButton size="sm" shape="pill" color="info" variant="outline"
                                     @click="()=>{dr.reqName=user.name;dr.reqHpNo=user.hpNo}">
                              예약자와 동일
                            </CButton>
                          </CCol>
                        </CRow>
                      </template>
                    </CInput>
                  </CCol>
                  <CCol md="6">
                    <CInput
                      label="출발지 연락처"
                      placeholder="보내는 분 휴대폰 번호를 입력해주세요"
                      type="text"
                      v-model="dr.reqHpNo"
                    />

                  </CCol>
                </CRow>

                <CRow>
                  <CCol md="6">
                    <CInput
                      placeholder="도착지 이름을 입력해 주세요"
                      type="text"
                      v-model="dr.acqName"
                    >
                      <template #label>
                        <CRow class="mb-1">
                          <CCol>
                            도착지 성함
                          </CCol>
                          <CCol class="text-right">
                            <CButton size="sm" shape="pill" color="info" variant="outline"
                                     @click="()=>{dr.acqName=user.name;dr.acqHpNo=user.hpNo}">
                              예약자와 동일
                            </CButton>
                          </CCol>
                        </CRow>

                      </template>
                    </CInput>
                  </CCol>
                  <CCol>
                    <CInput
                      label="도착지 연락처"
                      placeholder="도착지 휴대폰 번호를 입력해주세요"
                      type="text"
                      v-model="dr.acqHpNo"
                    />
                  </CCol>
                </CRow>


                <CRow>
                  <CCol md="6">
                    <addr-search label-type="st"
                                 ref="stAddr"
                                 :addr="dr.stAddr"
                                 :addr-dtl="dr.stAddrDtl"
                                 @selected="(e)=>setAddr('st',e)"
                    />
                  </CCol>
                  <CCol md="6">
                    <addr-search label-type="ed"
                                 ref="edAddr"
                                 :addr="dr.edAddr"
                                 :addr-dtl="dr.edAddrDtl"
                                 @selected="(e)=>setAddr('ed',e)"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="6" class="mb-1">
                    <CRow>
                      <CCol>
                        <BButton size="sm" variant="outline-warning" @click="setRoutes('add')" pill>
                          <BIconPlusCircle/> 경유지 추가
                        </BButton>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow >
                  <CCol md="6" class="mb-1" v-for="rt in dr.routes" :key="rt.idx">
                    <addr-search :label-type="`rt${rt.idx}`"
                                 :addr="rt.addr"
                                 :addr-dtl="rt.addrDtl"
                                 :key="stAddrKey"
                                 @selected="(e)=>setAddr('rt',e, rt)"

                    />

                    <BButton size="sm"
                             variant="outline-danger"
                             @click="setRoutes('del', rt.idx)" pill>
                      <BIconTrash/> 경유지{{rt.idx}} 삭제
                    </BButton>
                  </CCol>
                </CRow>


                <CRow v-if="false" v-show="!showCarInfo" class="mt-1">
                  <CCol>
                    <BButton size="sm" variant="outline-info"
                             @click="showCarInfo=!showCarInfo" pill>
                      <CIcon name="cil-car-alt"/>
                      상세정보
                    </BButton>

                  </CCol>
                </CRow>

                <CRow class="mt-1">
                  <CCol md="3">
                    <CInput
                      class="mt-1"
                      label="차량종류"
                      type="text"
                      v-model="dr.carKind"
                    />
                  </CCol>
                  <CCol md="3">
                    <CInput
                      class="mt-1"
                      label="차량번호"
                      type="text"
                      v-model="dr.plateNo"
                    />
                  </CCol>
                  <CCol md="3">
                    <CInput
                      class="mt-1"
                      label="소유주 성함"
                      type="text"
                      v-model="dr.carOwner"
                    />
                  </CCol>
                  <CCol md="3">
                    <CInput
                      class="mt-1"
                      label="소유주 연락처"
                      type="text"
                      v-model="dr.ownerHpNo"
                    />
                  </CCol>
                </CRow>

                <CRow class="mt-2">
                  <CCol>
                    <CInput
                      placeholder="요청 메세지 입력"
                      type="text"
                      v-model="dr.reqMessage"
                    />
                  </CCol>
                  <CCol>
                    <CRow>
                      <CCol>
                        <CButton class="m-0 p-0"
                                 @click="saveDrvReq"
                                 :disabled="dr.isCancled || dr.isCnclReq"
                                 v-show="!isSaveDone">
                          <CLoadingButton
                            :loading="isDrvSaving"
                            progress
                            :disabled="dr.isCancled || dr.isCnclReq"
                            timeout="1500"
                            color="success">
                            <BIconCalendarPlusFill/>&nbsp; <b>{{drvCode.map[dr.drvCode]}}</b> 예약 저장
                          </CLoadingButton>
                        </CButton>
                      </CCol>
                      <CCol class="text-right">
                        <CButton v-show="!isUpdateMode" @click="resetForm" color="danger">
                          <BIconArrowClockwise/>
                          Reset
                        </CButton>
                        <CButton v-show="isUpdateMode  && !dr.isCnclReq && !dr.isCancled" @click="cancelRequest('REQ')" color="danger">
                          <BIconTrashFill/>
                          예약 취소 요청
                        </CButton>
                        <CButton v-show="isUpdateMode && dr.isCnclReq && !dr.isCancled"
                                 class="ml-1"
                                 @click="cancelRequest('BACK')"
                                 color="warning">
                          <BIconArrowClockwise/>
                          취소요청 철회
                        </CButton>
                      </CCol>
                    </CRow>


                  </CCol>

                </CRow>

              </CCol>
            </CRow>



          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>


<script>
//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  alertConfirm,
  apiCall,
  cloneVar,
  sleep
} from '@/common/utils';
import con from '@/common/constants';
import {_driveRequest} from '@/common/schemas'
import AddrSearch from './AddrSearch.vue'

import moment from "moment";
import {toastSync} from "@/common/utils";



// const recvTypeMap = { manual: "매뉴얼", api: "API", socket: "소켓" };


//----------------------------------------------------------------------------------------------------
export default {
  name: "RequestForm",
  components: { AddrSearch },
  props: {
    item: { type: Object, default: null },
    small: { type: String, default: null },
  },

  data () {
    return {
      isNow: true,
      stLabel: 'st', // 출발지 라벨
      edLabel: 'ed', // 도착지 라벨
      stAddrKey: 0,
      edAddrKey: 0,
      user: this.$store.state.user,
      con,
      drvCode: con.drvCode,
      dr: cloneVar(_driveRequest),
      isUpdateMode: false,
      isDrvSaving: false,
      isSaveDone: false,
      machine: null,
      pickerShow: false,
      isUpdatingRecord: false,
      routeCount: 0,
      showCarInfo: false,
      payCodeMap: con.payCode.map,
      payCodeOpts: con.payCode.opts,
    }

  },
  async created(){
    console.log("--- RequestForm --- created---------------------", this.dr);
    try{
      if(this.item===null) {
        this.dr.drvYmd = moment().format('YYYY-MM-DD');
        this.dr.drvHms = moment().format('HH:mm:ss');
        this.isUpdateMode = false;
      }else{
        this.dr = this.item;
        this.isUpdateMode = true;
      }
    }catch(err){
      console.log(err);
    }
  },
  computed: {},

  mounted() {
    console.log("--- RequestForm --- mounted---------------------");
    // below is not work!
  },

  methods: {
    logging(e){
      console.log('event log', e);
    },
    setDrvDate(){
      if(this.isNow){
        this.dr.drvYmd = moment().format('YYYY-MM-DD');
        this.dr.drvHms = moment().format('HH:mm:ss');
      }
    },

    resetForm(){
      this.dr = cloneVar(_driveRequest);
      this.dr.drvYmd = moment().format('YYYY-MM-DD');
      this.dr.drvHms = moment().format('HH:mm:ss');
      this.isSaveDone = false;
      Object.assign(this.$refs.stAddr, this.$refs.stAddr.resetForm());
      Object.assign(this.$refs.edAddr, this.$refs.edAddr.resetForm());
    },
    setAddr(gbn, e=null, rt=null){
      console.log('set address--->',gbn, e);
      if(gbn==='st'){
        this.dr.stAddr=e.base;
        this.dr.stAddrDtl=e.dtl;
        this.dr.stXy=e.xy;
        this.dr.stRegionAddr  = e.region;
      }else if(gbn==='ed'){
        this.dr.edAddr=e.base;
        this.dr.edAddrDtl=e.dtl;
        this.dr.edXy=e.xy;
        this.dr.edRegionAddr  = e.region;
      }else if(gbn==='rt'){
        console.log(rt);
        rt.addr=e.base;
        rt.addrDtl=e.dtl;
        rt.xy=e.xy;
        rt.region = e.region;
      }
    },

    async setRoutes(cmd, idx=0){
      if(cmd==='add'){
        this.routeCount++;
        if(this.routeCount>5){
          await toastSync(this.$bvToast,'경유지는 최대 5개 까지 추가 할 수 있습니다.');
          this.routeCount--;
          return;
        }
        this.dr.routes.push({
          idx: this.routeCount,
          addr: '',
          addrDtl: '',
          xy: '',
          drvKm: 0,
          region: {
              rga: '', // 구주소 전체
              rg1: '', // 구주소 시도
              rg2: '', // 구주소 구군
              rg3: '', // 구주소 동면
              mNo: '', // main 번지수
              sNo: '', // 서브 번지수
              wpDt: null
            }
        });
      }else if(cmd==='del'){
        this.dr.routes = this.dr.routes.filter(i=> {
          return i.idx !== idx
        });

        let cnt=0;
        for(const rt of this.dr.routes){
          cnt++;
          rt.idx = cnt;
        }
        this.dr.routeCount = cnt;
      }
    },
    async saveDrvReq(){
      console.log( 'saveDrvReq --->', this.dr );

      let apiMethod = 'POST', apiParam = '';
      let rs = { code: 999, message: '', result: false };
      if(this.isDrvSaving) return;
      try{

        await this.checkDrvReqParam();
        this.isDrvSaving = true;

        if(this.isUpdateMode){
          apiMethod = 'PUT';
          apiParam = `/${this.dr._id}`;
          console.log( "to update drive data--->", this.dr );
        }

        const msg = `결제 방식은 ${this.payCodeMap[this.dr.payCode]} 입니다. 진행 하시겠습니까?`
        if( !await this.confirmModal(msg, '결제 확인' ) ) return;

        rs = await apiCall(apiMethod, '/api/drv-req'+apiParam , this.dr);
        console.log( 'saveDrvReq() --- result ----------->', rs );

        await sleep(1000);

        if(rs.code===200){
          this.isSaveDone = true;
          this.setElapsed(rs.result);
          this.dr = rs.result;
          this.$emit('saved', rs );
        }else{
          this.isSaveDone = false;
          if(rs.result) this.dr = rs.result;
        }
        await this.toastResult(rs, `예약 정보`);


      }catch(err){
        this.isDrvSaving = false;
        await this.alertDanger(err.message);브
        console.log( err.message );
      }finally{
        this.isDrvSaving = false;
      }
    },
    setElapsed(r){
      r['drvElapsed'] = moment().diff(moment(r.drvDt), 'minutes');
      r['stsElapsed'] = moment().diff(moment(r.stsDt), 'minutes');
      r['updElapsed'] = moment().diff(moment(r.updatedAt), 'minutes');
      r['elapsedStr'] = this.toElapseStr(r['drvElapsed']);
    },

    async cancelRequest(cmd){
      console.log( 'cancelRequest   cmd=', cmd )
      let rs;
      try{
        if(cmd==='REQ') {
          if (!await alertConfirm(this.$bvModal, '취소 수수료가 발생 할 수 있습니다. 예약취소 요청을 하시겠습니까? ')) return;
          rs = await apiCall('PUT', '/api/drv-req/cancel-request/' + this.dr._id, this.dr);
          await this.toastResult(rs, `탁송 예약 취소 요청`);
        }else if(cmd==='BACK'){
          if (!await alertConfirm(this.$bvModal, '예약취소 요청을 철회 하시겠습니까? ')) return;
          rs = await apiCall('PUT', '/api/drv-req/cancel-back/' + this.dr._id, this.dr);
          await this.toastResult(rs, `취소 요청 철회`);
        }else{
          alert('CMD NOT FOUND');
          return;
        }

        this.$emit('saved', rs );

      }catch(err){
        console.error(err);
        alert(err.message);
      }
    },

    async checkDrvReqParam(){
      const p = this.dr;
      if(!p.drvYmd) throw new Error('예약 날짜가 없습니다.')
      if(!p.drvHms) throw new Error('예약 시간이 없습니다.')
      if(!p.reqName) throw new Error('출발지 성함이 없습니다.')
      if(!p.reqHpNo) throw new Error('출발지 연락처가 없습니다.')
      if(!p.acqName) throw new Error('도착지 성함이 없습니다.')
      if(!p.acqHpNo) throw new Error('도착치 연락처가 없습니다.')
      if(!p.stAddr) throw new Error('출발지 주소가 없습니다.')
      if(!p.edAddr) throw new Error('도착지 주소가 없습니다.')

    }

  }
}
</script>
